import React from "react";
import "./ChooseUsCard1.styled.scss";
import { Image, Container } from "react-bootstrap";
import { Box, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import workspace from "../../Assets/images/company-img.png";
import vector from "../../Assets/images/greater-than-vector.png";
import Dots from "../../Assets/images/Dots.png";
import { Link, useNavigate } from "react-router-dom";
export default function ChooseUsCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate=useNavigate()
  const content = [
    {
      id: "01",
      heading: "Agile Methodology",
      description:
        "Successful projects are built around the Agile Methodology. Silicon Nexus is the platform for those who want to join an agile project, make a change in their working methods or just find out more about it.",
      color: "number-one",
    },
    {
      id: "02",
      heading: "Get MVP Faster",
      description:
        "Starting from design of a software, architecture and basic modules. We embark on developing modules to demonstrate functionality and scope of product. Share your idea and we would build MVP in three weeks.",
      color: "number-two",
    },
    {
      id: "03",
      heading: "After Sales Support",
      description:
        "We are a customer-centric service provider, dedicated to providing the best after-sales support for our clients. We strive to provide you with a quick and efficient solution.",
      color: "number-three",
    },
  ];
  const headingStyle = {
    textAlign: isMobile ? "center" : "start",
  };
  const headingContentStyle = {
    fontSize: isMobile ? "18px" : "1.5rem",
    fontWeight: "700",
    opacity: "1",
    marginLeft: isTablet ? "10px" : "",
  };
  const fontDescriptionStyle = {
    fontSize: isMobile ? "12px" : "1rem",
    marginLeft: isTablet ? "10px" : "",
  };
  const imgStyle = {
    
    width: isMobile || isTablet ? "78%" : "63%",
  };
  const numberStyle = {
    textAlign: isMobile || isTablet ? "center" : "start",
  };
  
  const handleAboutUsClick=()=>{
    navigate("/about-us")
  }
  return (
    <Container  className="who-we-are-fluid-container">
      <Container fluid  >
    <Box
      className="choose-us-section-container "
      maxWidth={isMobile ? "100%" : "100%"}
      mt={isMobile ? 0 : 10}
      style={{marginBottom:'40px', }}
    >
      
      <Grid className="child-container-text" container spacing={isMobile ? 0 : 5} mt={isMobile || isTablet ? 2 : 5} style={{ padding: isMobile ? "0px 10px" : "0px" }}>
        <Grid sm={12} sx={12} md={6} lg={6} textAlign="center" style={{ display: isMobile ? "none" : "block" }} >
          
          <Image
            src={workspace}
            alt=""
            fluid
            className="img-responsive"
            style={imgStyle}
          />
        </Grid>
        <Grid className="content" sm={12} sx={12} md={6} lg={6} style={ {marginTop:"-20px"} }>
          <h1 className="who-are-heading" >Who we <span style={{ color: "#1480F3" }}>are</span></h1>
          <p className="who-are-para1" >Play hard, work hard, and explore the creative mind</p>
          <p className="who-are-para2"  >
          Silicon Nexus is a tech company leading in the 4th industrial revolution, providing product development and idea validation. Our expertise lies in several areas including hardware design, embedded systems, web applications, blockchain development, and machine learning to enhance business efficiency and performance. Additionally, our collaboration with R&D groups goes beyond developing cutting-edge ideas as we take on complexity to ensure successful results throughout clients' operations.
          </p>
          <div className="dots-div">
            <button className="about-us" onClick={handleAboutUsClick}>
              About us
              <span><img src={vector} /></span>
            </button>
            <img src={Dots} style={{ display: isMobile ? "none" : "block" }} />
          </div>
        </Grid>
      </Grid>
    </Box>
    
    </Container>
    </Container>
  );
}
