import React, { useState, useEffect } from "react";
import Banner from "../Components/Banner/Banner";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import {
  Card,
  Box,
  Button,
  Grid,
  TextField,
  Container,
  CardContent,
  Alert,
} from "@mui/material";
import { apiFileFormPost, apiGET } from "../api";
import { useRef } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Typography } from "@mui/material";
// import Box from "@mui/material"
import Footer from "../Components/Footer/Footer";
import "./styles.scss";
import arrowRight from "../Assets/images/arrow-right.png";
import { Flex } from "../Components/Styles/Flex";
import blogBottomCalendar from "../../src/Assets/images/blog-bottom-calendar.png";
import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
// import { Grid } from 'swiper';
export const HiringForm = () => {
  const [contactDisplay, setContactDisplay] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const checkList = [
    {
      value: "Internship",
    },
    {
      value: "Onsite job",
    },
  ];
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [religion, setReligion] = useState("");
  const [city, setCity] = useState("");
  const [applyFor, setApplyFor] = useState([]);
  const [lastCompany, setLastCompany] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [experience, setExperience] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [designation, setDesignation] = useState("");
  const [status, setStatus] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputFile = useRef(null);
  // const handleCheckboxChange = (event) => {

  //   var updatedList = { ...applyFor };

  //   if (event.target.checked) {
  //     updatedList = [...applyFor, event.target.value];
  //   } else {
  //     updatedList.splice(applyFor.indexOf(event.target.value), 1);
  //   }

  //   setApplyFor(updatedList);

  // };

  const fontCheckStyle = {
    fontSize: isMobile ? "12px" : "1rem",
    borderRadius: "12px",
    width: "13px",
    height: "9px",
    marginTop: "10px",
  };
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const handleCheckboxChange = (event) => {
    var updatedList = { ...applyFor };

    if (event.target.checked) {
      updatedList = [...applyFor, event.target.value];
    } else {
      updatedList.splice(applyFor.indexOf(event.target.value), 1);
    }
    setApplyFor(updatedList);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !email || !phone || !selectedFile) {
      alert(
        "Please fill in all required fields: name, email, phone, and File."
      );
      return;
    }
    setIsLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert("Email is not valid");
      return;
    }

    let formData = new FormData();
    var data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      summary: description,
      phone: phone,
      city: city,
      state: state,
      religion: religion,
      last_company: lastCompany,
      designation: designation,
      notice_period: noticePeriod,
      experience: experience,
      current_salary: currentSalary,
      expected_salary: expectedSalary,
      applying_for: applyFor,
      job: jobId,
    };
    formData.append("image", selectedFile);
    formData.append("data", JSON.stringify(data));
    const jobData = Object.fromEntries(formData);
    console.log("jobData", jobData);
    apiFileFormPost("apply_job/apply_for_job", jobData)
      .then((res) => {
        if (!res.error) {
          setStatus("Form submitted successfully");
          setFirstName("");
          setLastName("");
          setEmail("");
          setDescription("");
          setPhone("");
          setState("");
          setReligion("");
          setApplyFor([]);
          setCity("");
          setSelectedFile(null);
          setLastCompany("");
          setNoticePeriod("");
          setExperience("");
          setCurrentSalary("");
          setExpectedSalary("");
          setDesignation("");
          inputFile.current.value = null;
          setTimeout(() => {
            setStatus("");
            setIsLoading(false);
          }, 3000);
        } else {
          setStatus(res.error_message);
        }
      })
      .catch((err) => {
        setStatus(err);
      });
  };
  const path = [
    {
      label: "Home",
      url: "/",
    },
  ];

  useEffect(() => {
    getJobsList();
  }, []);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const getJobsList = () => {
    apiGET("job/get_published_jobs")
      .then((res) => {
        const sortedJobs = res.jobs.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setJobs(sortedJobs);
        setFilteredJobs(sortedJobs.slice(0, 3));
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getJobsById();
  }, [id]);

  const getJobsById = () => {
    apiGET(`job/${id}`)
      .then((res) => {
        setJobId(res.job);
        console.log("Blog API response by id:", res.job);
      })
      .catch((err) => {
        console.log("Blog API error:", err);
      });
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  const handleFirstNameChange = (event) => {
    const value = event.target.value;
    // Validate input - accept only characters
    if (/^[A-Za-z]+$/.test(value) || value === "") {
      setFirstName(value);
    }
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;
    // Validate input - accept only characters
    if (/^[A-Za-z]+$/.test(value) || value === "") {
      setLastName(value);
    }
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    // Validate input - accept numbers and special characters, but not alphabetic characters
    if (/^[0-9\W]+$/.test(value) || value === "") {
      setPhone(value);
    }
  };

  const showCD = () => {
    setContactDisplay(true);
  };
  const hideCD = () => {
    setContactDisplay(false);
  };

  return (
    <div>
{/* <Helmet>
  <title>Hiring Form</title>
  <meta name="description" content="here is HiringForm "/>
  <link rel="canonical" href="/hiringform"></link>
</Helmet> */}
      <Banner
        img="https://res.cloudinary.com/dtju0fkhl/image/upload/v1666868281/blogs_jkgovi.png"
        firstWord=""
        secondWord="Get Hired"
        showCD={showCD}
        hideCD={hideCD}
        description="Innovate and grow with us."
        path={[{ url: "/", label: "Home" }]}
        current="Hiring-Form"
      />
      <div>
        <div style={{ display: contactDisplay ? "block" : "none" }}>
          <Grid container spacing={2}>
            <Container>
              <Grid
                container-fluid
                columnSpacing={8}
                rowSpacing={6}
                item
                xs={8}
                mt={5}
                ml={40}
              >
                {jobId && (
                  <h3
                    className="hiring-section-heading"
                    style={{ textAlign: "left", fontFamily: "Poppins" }}
                  >
                    <span style={{ color: "#000000" }}>
                      {jobId.job_title.split(" ")[0]}
                    </span>
                    <span style={{ color: "#177FF0" }}>
                      {jobId.job_title.substring(
                        jobId.job_title.indexOf(" ") + 0
                      )}
                    </span>
                  </h3>
                )}
                {jobId && <h5>{jobId.position}</h5>}

                <label className="applyFor">Applying for*</label>
                <Container>
                  <Row className="text-center" style={{ maxWidth: "720px" }}>
                    {checkList.map((item, index) => (
                      <Col
                        sm={12}
                        xs={12}
                        md={6}
                        lg={4}
                        key={index}
                        className="p-1"
                        alignItems="center"
                      >
                        <div style={{ display: "flex" }}>
                          <Form.Check
                            inline
                            style={fontCheckStyle}
                            name="applyFor"
                            type="checkbox"
                            value={item.value}
                            onChange={handleCheckboxChange}
                            // checked={selectedCheckbox === item.value} // Check if the checkbox is selected
                          />
                          <Typography
                            className="selection-text-card"
                            style={{
                              color: "black",
                              // marginBottom: '40px',
                              marginTop: "8px",
                            }}
                          >
                            {item.value}
                          </Typography>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
                <label className="personal-info">Personal Information</label>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      value={lastName}
                      onChange={handleLastNameChange}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="phone"
                      name="phone"
                      label="Phone Number"
                      value={phone}
                      onChange={handlePhoneChange}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Your Email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item mt={2} xs={2} sm={4} md={4}>
                    <TextField
                      required
                      id="city"
                      name="city"
                      label="City"
                      value={city}
                      onChange={(event) => {
                        setCity(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item mt={2} xs={2} sm={4} md={4}>
                    <TextField
                      required
                      id="state"
                      name="state"
                      label="State"
                      value={state}
                      onChange={(event) => {
                        setState(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid mt={2} item xs={2} sm={4} md={4}>
                    <TextField
                      required
                      id="religion"
                      name="religion"
                      label="Religion"
                      value={religion}
                      onChange={(event) => {
                        setReligion(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <label className="Technical-Info">Technical Information</label>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6} mt={2}>
                    <TextField
                      // required
                      id="lastCompany"
                      name="lastCompany"
                      label="Company Name"
                      value={lastCompany}
                      onChange={(event) => {
                        setLastCompany(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      // required
                      id="designation"
                      name="designation"
                      label="Designation"
                      value={designation}
                      onChange={(event) => {
                        setDesignation(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="notice_period"
                      name="notice_period"
                      label="Notice Period"
                      value={noticePeriod}
                      onChange={(event) => {
                        setNoticePeriod(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      required
                      id="experience"
                      name="experience"
                      label="Experience"
                      value={experience}
                      onChange={(event) => {
                        setExperience(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      id="currentSalary"
                      name="currentSalary"
                      label="Current Salary"
                      value={currentSalary}
                      onChange={(event) => {
                        setCurrentSalary(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      id="expectedSalary"
                      name="expectedSalary"
                      label="Expected Salary"
                      value={expectedSalary}
                      onChange={(event) => {
                        setExpectedSalary(event.target.value);
                      }}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <label className="Technical-Info">Summary</label>
                <Grid xs={12} mt={2}>
                  <TextField
                    name="description"
                    id="outlined-multiline-static"
                    placeholder="share your experience..."
                    multiline
                    rows={6}
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    style={{ height: "184px" }}
                  />
                </Grid>
                <label className="Technical-Info">Upload</label>
                <br></br>
                <label>Upload Your CV / Resume</label>
                <Box className="uploadcv">
                  <input
                    name="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    type="file"
                    ref={inputFile}
                  />
                </Box>
                <Box className="sub-btn">
                  <button
                    className="submit-button-contact-us-page"
                    style={{
                      backgroundColor: "#1480F3",
                      marginTop: "20px",
                      width: "150px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="spinner"></div>
                    ) : (
                      <>
                        <Button style={{ color: "white" }} type="submit">
                          Submit
                        </Button>
                        <img src={arrowRight} alt="Arrow Right" />
                      </>
                    )}
                  </button>
                  <br></br>
                  {status && <Alert severity="success">{status}</Alert>}
                </Box>
              </Grid>
            </Container>
            <Grid item xs={4} mt={5}>
              <Card id="tagcard">
                <Box className="tag-box-title" mt={3}>
                  <h3 className="tag-section-heading">Latest Openings</h3>
                </Box>
                <CardContent>
                  {jobs
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .slice(0, 3)
                    .map((job, index) => (
                      <Link
                        to={`/hiringform/${job._id}`}
                        className="news-card"
                        key={index}
                      >
                        <Box mb={2}>
                          <Grid container className="recent-job-post">
                            <Grid item xs={8} sm={8} md={8} lg={12} xl={8}>
                              <p
                                className="recent-post-title"
                                style={{ color: "white" }}
                              >
                                {job.job_title}
                              </p>
                              <div className="recent-post-date-text">
                                <img
                                  src={blogBottomCalendar}
                                  alt="blogBottomCalendar"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "10px",
                                    marginTop: "-5px",
                                  }}
                                />
                                {formatDate(job.createdAt)}
                                {/* <div className="line"></div> */}
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Link>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};
