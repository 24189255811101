/**
 * @author Nadir H.
 * @version 1.0
 */

import React from "react";
import { Box } from "@mui/material";

//resources

export default function CurvedArrow() {
  return (
    <Box mt={-1}>
      <svg width="max-content" height="190" className="curved-svg">
        <marker
          id="endarrow"
          markerWidth="4"
          markerHeight="4"
          refX="0"
          refY="1"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <polygon points="0 0, 4 2, 0 4" fill="#ffffff" />
        </marker>

        
        <path d="M2,34 C67,55 69,164 10,185" markerEnd="url(#endarrow)" />
       
      </svg>
    </Box>
  );
}
