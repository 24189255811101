/**
 * @author
 * @version 1.0
 */

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import { motion } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import logo from "../../Assets/images/logo.png";
import icon002 from "../../Assets/images/icon002.png";

import "./menu.scss";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Link1 from "@mui/material/Link";

export default function Menu({ menuOpen, setMenuOpen,showCD,hideCD }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
 
  }, []);
  const socialIconStyle = {
    fontSize: isMobile ? "18px" : "1.5rem",
    color: "#1480F3",
  };

  return (
    <div className="row">
      <div className={"menu " + (menuOpen && "active")}>
        <div className="left-box">
          <div className="logo-container">
            <Link1 href="/">
              <Image src={logo} alt="" fluid />
            </Link1>
          </div>
          {menuOpen ? (
            <Particles
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                    trail: {
                      delay: 0.4,
                      quantity: 5,
                      pauseOnStop: true,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#1480F3",
                  },
                  links: {
                    color: "#1480f3",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: false,
                  },
                  move: {
                    directions: "none",
                    enable: true,
                    outModes: {
                      default: "bounce",
                    },
                    random: false,
                    speed: 6,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                    },
                    value: 20,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "square",
                  },
                  size: {
                    value: { min: 1, max: 5 },
                  },
                 
                },
                detectRetina: true,
              }}
            />
          ) : null}
          {isMobile || isTablet ? (
            <div>
              <nav className="new-menu js-menu-items-wrapper">
                <ol className="js-menu-items-list">
                  <li
                    className="new-menu-item js-menu-item is-active"
                    data-morph="M 418.1,159.8 C 460.9,222.9 497,321.5 452.4,383.4 417.2,432.4 371.2,405.6 271.3,420.3 137.2,440 90.45,500.6 42.16,442.8 -9.572,381 86.33,289.1 117.7,215.5 144.3,153.4 145.7,54.21 212.7,36.25 290.3,15.36 373.9,94.6 418.1,159.8 Z"
                  >
                    <Link to="/about-us" className="link-item">
                      About Us
                    </Link>
                  </li>

                  {/* removal of services page */}
                  {/* <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 402.7,215.5 C 433.9,280.4 488.1,367.2 447.7,426.8 410.1,482.2 316.7,460.2 249.7,460.6 182.8,461.1 88.08,485.5 51.26,429.5 10.29,367.3 73.19,279.4 106.9,213 141.8,144 176.6,33.65 253.9,33.7 332.2,33.75 368.8,144.9 402.7,215.5 Z"
                  >
                    <Link to="/our-services" className="link-item">
                      Services
                    </Link>
                  </li> */}
                  {/* <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 451.5,185.8 C 441.5,266.2 339.6,305 272.3,350.2 207.7,393.6 226.7,444.7 182.6,447.9 132.8,451.4 83.97,399.9 66.37,353.1 34.6,268.4 41.16,141.8 112,85.44 186.1,26.329999999999984 313.8,54.099999999999994 396,101.4 425.2,118.2 455.6,152.4 451.5,185.8 Z"
                  >
                    <Link to="/our-portfolio" className="link-item">
                      Portfolio
                    </Link>
                  </li>
                
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 451.5,185.8 C 441.5,266.2 339.6,305 272.3,350.2 207.7,393.6 226.7,444.7 182.6,447.9 132.8,451.4 83.97,399.9 66.37,353.1 34.6,268.4 41.16,141.8 112,85.44 186.1,26.329999999999984 313.8,54.099999999999994 396,101.4 425.2,118.2 455.6,152.4 451.5,185.8 Z"
                  >
                    <Link to="#" className="link-item">
                      Case Studies
                    </Link>
                  </li> */}
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 451.5,185.8 C 441.5,266.2 339.6,305 272.3,350.2 207.7,393.6 226.7,444.7 182.6,447.9 132.8,451.4 83.97,399.9 66.37,353.1 34.6,268.4 41.16,141.8 112,85.44 186.1,26.329999999999984 313.8,54.099999999999994 396,101.4 425.2,118.2 455.6,152.4 451.5,185.8 Z"
                  >
                    <Link to="/staff-augmentation" className="link-item">
                      Staff Augmentation
                    </Link>
                  </li>
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 378.1,121.2 C 408.4,150 417.2,197.9 411,245.8 404.8,293.7 383.5,341.7 353.4,370.7 303.2,419.1 198.7,427.7 144.5,383.8 86.18,336.5 67.13,221.3 111.9,161 138.6,125 188.9,99.62 240.7,90.92 292.4,82.24 345.6,90.32 378.1,121.2 Z"
                  >
                    <Link to="/career" className="link-item">
                      Careers
                    </Link>
                  </li>
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 378.1,121.2 C 408.4,150 417.2,197.9 411,245.8 404.8,293.7 383.5,341.7 353.4,370.7 303.2,419.1 198.7,427.7 144.5,383.8 86.18,336.5 67.13,221.3 111.9,161 138.6,125 188.9,99.62 240.7,90.92 292.4,82.24 345.6,90.32 378.1,121.2 Z"
                  >
                    <Link to="/contact-us" className="link-item">
                      Get In Touch
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          ) : (
            <div >
              <nav className="new-menu js-menu-items-wrapper"  >
                <ol className="js-menu-items-list">
                  <li
                    className="new-menu-item js-menu-item is-active"
                    data-morph="M 418.1,159.8 C 460.9,222.9 497,321.5 452.4,383.4 417.2,432.4 371.2,405.6 271.3,420.3 137.2,440 90.45,500.6 42.16,442.8 -9.572,381 86.33,289.1 117.7,215.5 144.3,153.4 145.7,54.21 212.7,36.25 290.3,15.36 373.9,94.6 418.1,159.8 Z"
                  >
                    <div className="hover-box">About Us</div>
                    <Link1 href="/about-us" className="link-item">
                      About Us
                    </Link1>
                  </li>
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 402.7,215.5 C 433.9,280.4 488.1,367.2 447.7,426.8 410.1,482.2 316.7,460.2 249.7,460.6 182.8,461.1 88.08,485.5 51.26,429.5 10.29,367.3 73.19,279.4 106.9,213 141.8,144 176.6,33.65 253.9,33.7 332.2,33.75 368.8,144.9 402.7,215.5 Z"
                  >
                    <div className="hover-box">Services</div>
                    <Link1  className="link-item">
                      Services
                    </Link1>
                    <ol className="sub-new-menu">
                      <li className="new-menu-item">
                        <Link1
                          href="/service/embedded-service"
                          className="link-new-menu-item"
                        >
                          Embedded Systems
                        </Link1>
                      </li>
                      <li className="new-menu-item">
                        <Link1
                          href="/service/web-design-service"
                          className="link-new-menu-item"
                        >
                          Website Development
                        </Link1>
                      </li>
                      <li className="new-menu-item">
                        <Link1
                          href="/service/mobile-design-service"
                          className="link-new-menu-item"
                        >
                          Mobile App Development
                        </Link1>
                      </li>
                      <li className="new-menu-item">
                        <Link1
                          href="/service/ui-ux-design-service"
                          className="link-new-menu-item"
                        >
                          UI/UX Design
                        </Link1>
                      </li>
                      <li className="new-menu-item">
                        <Link1
                          href="/service/block-chain-service"
                          className="link-new-menu-item"
                        >
                          Blockchain Development
                        </Link1>
                      </li>
                      <li className="new-menu-item">
                        <Link1
                          href="/service/ai-mi-service"
                          className="link-new-menu-item"
                        >
                          AI and ML Development
                        </Link1>
                      </li>
                    </ol>
                  </li>
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 451.5,185.8 C 441.5,266.2 339.6,305 272.3,350.2 207.7,393.6 226.7,444.7 182.6,447.9 132.8,451.4 83.97,399.9 66.37,353.1 34.6,268.4 41.16,141.8 112,85.44 186.1,26.329999999999984 313.8,54.099999999999994 396,101.4 425.2,118.2 455.6,152.4 451.5,185.8 Z"
                  >
                    <div className="hover-box">Insight</div>
                    <Link to="#" className="link-item">
                      Insights
                    </Link>
                    <ol className="sub-new-menu">
                      
                      <li className="new-menu-item">
                        <Link1
                          href="/staff-augmentation"
                          className="link-new-menu-item"
                        >
                          Staff augmentation
                        </Link1>
                      </li>
                        <li className="new-menu-item">
                        <Link to="/blog" className="link-new-menu-item">
                          Blogs
                        </Link>
                      </li> 
                    </ol>
                  </li>
                  <li
                    className="new-menu-item js-menu-item"
                    data-morph="M 378.1,121.2 C 408.4,150 417.2,197.9 411,245.8 404.8,293.7 383.5,341.7 353.4,370.7 303.2,419.1 198.7,427.7 144.5,383.8 86.18,336.5 67.13,221.3 111.9,161 138.6,125 188.9,99.62 240.7,90.92 292.4,82.24 345.6,90.32 378.1,121.2 Z"
                  >
                    <div className="hover-box">Careers</div>
                    <Link1 href="/career" className="link-item">
                      Careers
                    </Link1>
                  </li>
                </ol>
              </nav>
            </div>
          )}
        </div>
        <div className="sales-container">
          <img src={icon002} alt="" />
          <div className="info-container">
            <h3 className="menu-info-heading">INFO</h3>
            <p className="menu-info-text">info@silicon-nexus.com</p>
          </div>
        </div>
        <h3 className="follow-heading">Follow</h3>
        <div className="left-icon-section">
          <ul className="menu-media-icons">
            <li>
              <a href="https://www.facebook.com/siliconnexus/">
                <i className="fab fa-facebook-f icon"></i>{" "}
              </a>
            </li>
            <li>
              <a href="https://mobile.twitter.com/nexussilicon">
                <i className="fab fa-twitter icon"></i>
              </a>
            </li>
            <li>
              <a href="https://pk.linkedin.com/company/siliconnexus">
                <i className="fab fa-linkedin-in icon"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/silicon_nexus/">
                <i className="fab fa-instagram icon"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={"right-box " + (menuOpen && "active")}>
        <p>
          © 2022 SiliconNexus.
          <br />
          All Rights Reserved.
        </p>
        <Link1 href="/contact-us">
          <motion.h3
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            initial={{
              rotateY: 360,
            }}
          >
            GET IN TOUCH
          </motion.h3>
        </Link1>
        <div className="icon-section">
          <ul className="list-unstyled social-circle-icons">
            <li>
             <a
                  href="https://www.linkedin.com/company/siliconnexus/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
              <i
                    className="fa fa-linkedin social-icon"
                    style={socialIconStyle}
                  ></i>
              </a>
            </li>
            <li>
            <a
                  href="https://mobile.twitter.com/nexussilicon"
                  target="_blank"
                  rel="noreferrer"
                >
              <i
                    className="fa fa-twitter social-icon"
                    style={socialIconStyle}
                  ></i>
              </a>
            </li>
            <li>
            <a
                  href="https://www.instagram.com/silicon_nexus/"
                  target="_blank"
                  rel="noreferrer"
                >
                <i
                    className="fa fa-instagram social-icon"
                    style={socialIconStyle}
                  ></i>
              </a>
            </li>
            <li>
            <a
                  href="https://www.facebook.com/siliconnexus/"
                  target="_blank"
                  rel="noreferrer"
                >
             <i
                    className="fa fa-facebook social-icon"
                    style={socialIconStyle}
                  ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => {setMenuOpen(false); if(showCD){showCD()} }}
        className={"close-button " + (menuOpen && "active")}
      >
        <div className="close-button-image"></div>
      </div>
    </div>
  );
}
