import React from "react";
import "./styles.scss";
export default function FooterBox({
  icon,
  heading,
  primaryText,
  secondaryText,
  link,
}) {
  return (
    <div className="footer-box-main-container">
      <img  className="footer-box-image" src={icon} alt="" />
      <span className="footer-box-heading-text">{heading}</span>
      <span className="footer-box-primary-text">{primaryText}</span>
      <span className="footer-box-primary-text">{secondaryText}</span>
      <span className="footer-box-link-text">{link}</span>
    </div>
  );
}
