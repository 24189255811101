import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  & > div,
  & > ul {
    flex: 1;
  }

  .title-bar {
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0.18px;
    color: #010101;
    margin-top: 20px;
  }
`;
