import React, { useState } from "react";
import "./styles.scss";
import EngagementModelCard from "../EngagementModelCard/EngagementModelCard";
import engagement004 from "../../Assets/images/engagement004.png";
import engagement005 from "../../Assets/images/engagement005.png";
import engagement006 from "../../Assets/images/engagement006.png";
import engagement007 from "../../Assets/images/engagement007.png";
import engagement008 from "../../Assets/images/engagement008.png";
import engagement009 from "../../Assets/images/engagement009.png";
import ModelDetails from "../NewEngagementCard/ModelDetails";

import engagement001 from "../../Assets/images/engagement003.png";
import engagement002 from "../../Assets/images/engagement002.png";
import engagement003 from "../../Assets/images/engagement001.png";

import ic_eng1_choose_tech from "../../Assets/images/ic_eng1_choose_tech.png";
import ic_eng1_choose_stack from "../../Assets/images/ic_eng1_choose_stack.png";
import ic_eng1_choose_tools from "../../Assets/images/ic_eng1_choose_tools.png";
import ic_eng1_schedule_call from "../../Assets/images/ic_eng1_schedule_call.png";
import ic_eng1_tech_interviewk from "../../Assets/images/ic_eng1_tech_interview.png";
import ic_eng1_finalize_resource from "../../Assets/images/ic_eng1_finalize_resource.png";

import ic_eng2_analyze_requirements from "../../Assets/images/ic_eng2_analyze_requirements.png";
import ic_eng2_team_structure from "../../Assets/images/ic_eng2_team_structure.png";
import ic_eng2_team_scruitiny from "../../Assets/images/ic_eng2_team_scruitiny.png";
import ic_eng2_make_epic from "../../Assets/images/ic_eng2_make_epic.png";
import ic_eng2_project_roadmap from "../../Assets/images/ic_eng2_project_roadmap.png";
import ic_eng2_team_finalization from "../../Assets/images/ic_eng2_team_finalization.png";

import ic_eng3_brainstorm from "../../Assets/images/ic_eng3_brainstorm.png";
import ic_eng3_usp_validation from "../../Assets/images/ic_eng3_usp_validation.png";
import ic_eng3_product_srs from "../../Assets/images/ic_eng3_product_srs.png";
import ic_eng3_time_cost_analysis from "../../Assets/images/ic_eng3_time_cost_analysis.png";
import ic_eng3_project_arhictecture from "../../Assets/images/ic_eng3_project_arhictecture.png";
import ic_eng3_meeting from "../../Assets/images/ic_eng3_meeting.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import EjectRoundedIcon from '@mui/icons-material/EjectRounded';
export default function EngagementModel() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [firstBox, isFirstBoxVisible] = useState(true);
  const [secondBox, isSecondBoxVisible] = useState(false);
  const [thirdBox, isThirdBoxVisible] = useState(false);
  const [initial,setInitial] = useState(false)


  const engagements = [
    {
      id: 0,
      heading: "Hire A Dedicated Resource",
      image: engagement001,
      process: [
        "Choose Your Technology",
        "Choose Your Stack",
        "Choose Tools",
        "Finalize Resource",
        "Schedule Technical Interview",
        "Schedule A Call For Requirement Analysis",
      ],
      icons: [
        ic_eng1_choose_tech,
        ic_eng1_choose_stack,
        ic_eng1_choose_tools,
        ic_eng1_finalize_resource,
        ic_eng1_tech_interviewk,
        ic_eng1_schedule_call,
      ],
    },
    {
      id: 1,
      heading: "Hire Dedicated Team",
      image: engagement002,
      process: [
        "Analyze Requirements",
        "Team Structure / Resources Classification",
        "Team Scrutiny",
        "Team Finalization",
        "Project Roadmap Discussion",
        "Project Man Make An EPIC / Sprints",
      ],
      icons: [
        ic_eng2_analyze_requirements,
        ic_eng2_team_structure,
        ic_eng2_team_scruitiny,
        ic_eng2_make_epic,
        ic_eng2_project_roadmap,
        ic_eng2_team_finalization,
      ],
    },
    {
      id: 2,
      heading: "Partner an Idea",
      image: engagement003,
      process: [
        "Brain Storming / Ideation",
        "Idea Validation / USP Classification",
        "Finalizing Product SRS And Platform Selection",
        "Brief Meeting On User Journey, Experience And Prototyping",
        "Project Architecture, Standards And Design Flow",
        "Analysis Of Resources, time And Cost",
      ],
      icons: [
        ic_eng3_brainstorm,
        ic_eng3_usp_validation,
        ic_eng3_product_srs,
        ic_eng3_time_cost_analysis,
        ic_eng3_project_arhictecture,
        ic_eng3_meeting,
      ],
    },
  ];
  return (
    <div className="engagement-model-main-container">
      <div className="engagement-model-background">
        <Grid className="">
          <Grid item sx={12} sm={12} md={12} lg={12} >
           
            <div style={{ padding: isMobile ? "10px 10px" : "" }}>
              <p className="engagement-model-heading" style={{ textAlign: "center", color: "white", fontSize: "40px", fontWeight: "bold" }}>Our <span style={{ color: "#1480F3" }}>Engagement Model</span></p>
              <p className="engagement-model-paragraph" style={{ textAlign: "center", color: "#ffffff", fontSize: "24px", fontWeight: 400 }}>
                {/* We solve your biggest business pains and help you succeed. Here is how we can work together. */}
                Engagement Model is a revolutionary engagement system that empowers you to create addictive customer experiences.
                </p>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <Grid container className="engagement-model-grid-container">
            <Grid item sx={12} sm={12} md={4} lg={3}>
              <EngagementModelCard initial={initial}
                primaryText={"Hire"}
                secondaryText={"Dedicated Resource"}
                primaryImage={engagement005}
                secondaryImage={engagement004}
                onMouseEnter={() => {
                  isFirstBoxVisible(true);
                  isSecondBoxVisible(false);
                  isThirdBoxVisible(false);
                }}
                onMouseLeave={() => {
                  isSecondBoxVisible(false);
                  isThirdBoxVisible(false);
                }}
              />
              
              {(!isMobile && !isTablet) && firstBox && (

                <span className="model-details-direction-icon"><EjectRoundedIcon style={{ color: "#1480f3", fontSize: "65px", }} /></span>
              )}
            </Grid>
            <Grid item sx={12} sm={12} md={4} lg={2}>
              <EngagementModelCard  
                primaryText={"Hire"}
                secondaryText={"Dedicated Team"}
                primaryImage={engagement008}
                secondaryImage={engagement009}
                setInitial={setInitial}
                onMouseEnter={() => {
                  isFirstBoxVisible(false);
                  isSecondBoxVisible(true);
                  isThirdBoxVisible(false);
                  setInitial(false)
                }}
                onMouseLeave={() => {
                  isFirstBoxVisible(true);
                  isSecondBoxVisible(false);
                  isThirdBoxVisible(false);
                  setInitial(true)
                }}
              />
              {(!isMobile && !isTablet) && secondBox && (

                <span className="model-details-direction-icon"><EjectRoundedIcon style={{ color: "#1480f3", fontSize: "65px", }} /></span>
              )}
            </Grid>
            <Grid item sx={12} sm={12} md={4} lg={3}>
              <EngagementModelCard
                primaryText={"Partner"}
                secondaryText={"an Idea"}
                primaryImage={engagement007}
                secondaryImage={engagement006}
                setInitial={setInitial}
                onMouseEnter={() => {
                  isFirstBoxVisible(false);
                  isSecondBoxVisible(false);
                  isThirdBoxVisible(true);
                  setInitial(false)
                }}
                onMouseLeave={() => {
                  isFirstBoxVisible(true);
                  isThirdBoxVisible(false);
                  isSecondBoxVisible(false);
                  setInitial(true)
                }}
              />
              {(!isMobile && !isTablet) && thirdBox && (

                <span className="model-details-direction-icon"><EjectRoundedIcon style={{ color: "#1480f3", fontSize: "65px", }} /></span>
              )}
            </Grid>

          </Grid>
        </div>
        {(!isMobile && !isTablet) && (
          <>
            {firstBox ? <ModelDetails details={engagements[0]} /> : null}
            {secondBox ? <ModelDetails details={engagements[1]} /> : null}
            {thirdBox ? <ModelDetails details={engagements[2]} /> : null}
          </>
        )}

      </div>
    </div>
  );
}
