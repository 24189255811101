/**
 * @author Nadir H.
 * @version 1.0
 */

import React from "react";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// Web Images
import wMain1 from '../../Assets/images/web-tech/main1.png'
import wb1 from '../../Assets/images/web-tech/ajax.png'
import wb2 from '../../Assets/images/web-tech/angular.png'
import wb3 from '../../Assets/images/web-tech/asp.png'
import wb4 from '../../Assets/images/web-tech/django.png'
import wb5 from '../../Assets/images/web-tech/html.png'
import wb6 from '../../Assets/images/web-tech/vue.png'
import wb7 from '../../Assets/images/web-tech/js.png'
import wb8 from '../../Assets/images/web-tech/laravel.png'
import wb9 from '../../Assets/images/web-tech/mongodb.png'
import wb10 from '../../Assets/images/web-tech/react.png'



// Mobile Images
import mMain1 from '../../Assets/images/mobile-tech/main1.png'
import mb1 from '../../Assets/images/mobile-tech/android.png'
import mb2 from '../../Assets/images/mobile-tech/firbase.png'
import mb3 from '../../Assets/images/mobile-tech/flutter.png'
import mb4 from '../../Assets/images/mobile-tech/ios.png'
import mb5 from '../../Assets/images/mobile-tech/kotlin.png'
import mb6 from '../../Assets/images/mobile-tech/react-native.png'
import mb7 from '../../Assets/images/mobile-tech/swift.png'
import mb8 from '../../Assets/images/mobile-tech/xml.png'



//Embedded Systems
import eMain1 from '../../Assets/images/embedded-tech/main1.png'
import eb1 from '../../Assets/images/embedded-tech/accenture.png'
import eb2 from '../../Assets/images/embedded-tech/analog.png'
import eb3 from '../../Assets/images/embedded-tech/android.png'
import eb4 from '../../Assets/images/embedded-tech/arm.png'
import eb5 from '../../Assets/images/embedded-tech/at&t.png'
import eb6 from '../../Assets/images/embedded-tech/augmented.png'
import eb7 from '../../Assets/images/embedded-tech/aws.png'
import eb8 from '../../Assets/images/embedded-tech/azure.png'
import eb9 from '../../Assets/images/embedded-tech/bosch.png'
import eb10 from '../../Assets/images/embedded-tech/drive.png'



//BlockChain Systems
import bMain1 from '../../Assets/images/Blockchain-tech/main1.png'
import bb1 from '../../Assets/images/Blockchain-tech/binance.png'
import bb2 from '../../Assets/images/Blockchain-tech/Blockchain001.png'
import bb3 from '../../Assets/images/Blockchain-tech/defi.png'
import bb4 from '../../Assets/images/Blockchain-tech/dex.png'
import bb5 from '../../Assets/images/Blockchain-tech/fantom.png'
import bb6 from '../../Assets/images/Blockchain-tech/solidity.png'
import bb7 from '../../Assets/images/Blockchain-tech/solana.png'
import bb8 from '../../Assets/images/Blockchain-tech/terra.png'
import bb9 from '../../Assets/images/Blockchain-tech/truffle.png'
import bb10 from '../../Assets/images/Blockchain-tech/thereur.png'


// Ui/Ux
import uMain1 from '../../Assets/images/ui&ux-tech/main1.png'
import ub1 from '../../Assets/images/ui&ux-tech/ui-ux001.png'
import ub2 from '../../Assets/images/ui&ux-tech/ui-ux002.png'
import ub3 from '../../Assets/images/ui&ux-tech/ui-ux003.png'
import ub4 from '../../Assets/images/ui&ux-tech/ui-ux004.png'
import ub5 from '../../Assets/images/ui&ux-tech/ui-ux005.png'
import ub6 from '../../Assets/images/ui&ux-tech/ui-ux006.png'
import ub7 from '../../Assets/images/ui&ux-tech/ui-ux007.png'
import ub8 from '../../Assets/images/ui&ux-tech/ui-ux008.png'
import ub9 from '../../Assets/images/ui&ux-tech/ui-ux009.png'
import ub10 from '../../Assets/images/ui&ux-tech/ui-ux0010.png'


// Ai
import aMain1 from '../../Assets/images/ai-ml-tech/main1.png'
import ab1 from '../../Assets/images/ai-ml-tech/aiml001.png'
import ab2 from '../../Assets/images/ai-ml-tech/aiml002.png'
import ab3 from '../../Assets/images/ai-ml-tech/aiml003.png'
import ab4 from '../../Assets/images/ai-ml-tech/aiml004.png'
import ab5 from '../../Assets/images/ai-ml-tech/aiml005.png'
import ab6 from '../../Assets/images/ai-ml-tech/aiml006.png'
import ab7 from '../../Assets/images/ai-ml-tech/aiml007.png'
import ab8 from '../../Assets/images/ai-ml-tech/aiml008.png'
import ab9 from '../../Assets/images/ai-ml-tech/aiml009.png'
import ab10 from '../../Assets/images/ai-ml-tech/aiml0010.png'



import "./styles.scss";

// New Code
const TAGS = [
  [wb1, wb2, wb3, wb4, wb5, wb6, wb7, wb8, wb9, wb10],
  [mb1, mb2, mb3, mb4, mb5, mb6, mb7, mb8,],
  [eb1, eb2, eb3, eb4, eb5, eb6, eb7, eb8, eb9, eb10],
  [bb1, bb2, bb3, bb4, bb5, bb6, bb7, bb8, bb9, bb10],
  [ub1, ub2, ub3, ub4, ub5, ub6, ub7, ub8, ub9, ub10],
  [ab1, ab2, ab3, ab4, ab5, ab6, ab7, ab8, ab9, ab10],
];
// const TAGS = [b91, b92, b93, b94, b95, b9];
const DURATION = 100000;
const ROWS = 1;
const TAGS_PER_ROW = 50;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({ img }) => {
  // console.log("img", img);
  return (
    <div>
      {/* <span>#</span> {text} */}
      <img src={img} alt="" className="award-image1" />
    </div>
  );
};


export default function StackElements({ stackId }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));



  

  const webStack = [
    {
      // image: ic_web_1,
      image: wMain1,
      id: 0,
    },

  ];

  const mobileStack = [
    {
      // image: ic_mobile_1,
      image: mMain1,
      id: 0,
    }
  ];

  const embeddedStack = [
    {
      // image: esd_1,
      image: eMain1,
      id: 0,
    },
  ];

  const blockchainStack = [
    {
      // image: bchain_1,
      image: bMain1,
      id: 0,
    }
  ];

  const aiMlStack = [
    {
      // image: ai_1,
      image: aMain1,
      id: 0,
    }
  ];

  const uiUxStack = [
    {
      // image: ui_1,
      image: uMain1,
      id: 0,
    }
  ];

  const getStack = () => {
    if (stackId === 0) {
      return webStack;
    } else if (stackId === 1) {
      return mobileStack;
    } else if (stackId === 2) {
      return embeddedStack;
    } else if (stackId === 3) {
      return blockchainStack;
    } else if (stackId === 4) {
      return uiUxStack;
    } else {
      return aiMlStack;
    }
  };

  return (
    <Box
      flexDirection={"row"}
      textAlign="center"
      // className={`${isMobile ? "element-container" : ""}`}
      style={{ textAlign: isTablet ? "center" : "" }}
    >
      
      {
        getStack().map((element, index) => (
          <div style={{ marginTop: '60px', marginBottom: '20px' }} >
            <img
              src={element.image}
              alt={`technology_image`}
              className="tech-image"
              loading="lazy"
              width={isMobile || isTablet || isLaptop ? 100 : 200}
              height={isMobile || isTablet || isLaptop ? 100 : 200}
            />

            <div className="tag-list1"  style={{marginTop:stackId==4?"40px":null}}>
              {[...new Array(ROWS)].map((_, i) => (
                <InfiniteLoopSlider
                  key={i}
                  duration={random(DURATION - 5000, DURATION + 5000)}
                  reverse={i % 2}
                >
                  {shuffle(TAGS[stackId])
                    .map((tag, index) => (
                      <Tag img={tag} key={index} />
                    ))}
                </InfiniteLoopSlider>
              ))}
            </div>

          </div>
        ))
      }

    </Box>
  );
}
