/**
 * @author Nadir H.
 * @version 1.0
 */

import React from "react";
import { Box } from "@mui/material";

export default function DirectionArrow({ direction }) {
  return (
    <Box mt={3}>
      <svg
        width={"100%"}
        height={20}
        style={{
          transform: `rotate(${direction == "left" ? "180deg" : "0deg"})`,
        }}
      >
        <defs>
          <marker
            id="endarrow"
            markerWidth="4"
            markerHeight="4"
            refX="0"
            refY="2"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <polygon points="0 0, 4 2, 0 4" fill="#ffffff" />
          </marker>
        </defs>
        <line x1={"0"} y1={5} x2={"200"} y2={5} markerEnd={`url(#endarrow)`} />
      </svg>
    </Box>
  );
}
