import reactIcon from "../../Assets/images/web-tech/react.png"
import angularIcon from "../../Assets/images/web-tech/angular.png"
import vueIcon from "../../Assets/images/web-tech/vue.png"
import pythonIcon from "../../Assets/images/web-tech/python.png"
import djangoIcon from "../../Assets/images/web-tech/django.png"
import mongodbIcon from "../../Assets/images/web-tech/mongodb.png"
import htmlIcon from "../../Assets/images/web-tech/html.png"
import aspIcon from "../../Assets/images/web-tech/asp.png"
import ajaxIcon from "../../Assets/images/web-tech/ajax.png"
import laravelIcon from "../../Assets/images/web-tech/laravel.png"
import jsIcon from "../../Assets/images/web-tech/js.png"
import js1Icon from "../../Assets/images/web-tech/js1.png"
import phpIcon from "../../Assets/images/web-tech/php.png"
import androidIcon from "../../Assets/images/mobile-tech/android.png"
import iosIcon from "../../Assets/images/mobile-tech/ios.png"
import kotlinIcon from "../../Assets/images/mobile-tech/kotlin.png"
import flutterIcon from "../../Assets/images/mobile-tech/flutter.png"
import reactnativeIcon from "../../Assets/images/mobile-tech/react-native.png"
import swiftIcon from "../../Assets/images/mobile-tech/swift.png"
import xmlIcon from "../../Assets/images/mobile-tech/xml.png"
import accentureIcon from "../../Assets/images/embedded-tech/accenture.png"
import analogIcon from "../../Assets/images/embedded-tech/analog.png"
// import androidIcon from "../../Assets/images/embedded-tech/android.png"
import armIcon from "../../Assets/images/embedded-tech/arm.png"
import attIcon from "../../Assets/images/embedded-tech/at&t.png"
import augmentedIcon from "../../Assets/images/embedded-tech/augmented.png"
import awsIcon from "../../Assets/images/embedded-tech/aws.png"
import azureIcon from "../../Assets/images/embedded-tech/azure.png"
import boschIcon from "../../Assets/images/embedded-tech/bosch.png"
import driveIcon from "../../Assets/images/embedded-tech/drive.png"
import nordicIcon from "../../Assets/images/embedded-tech/nordic.png"
import nxpIcon from "../../Assets/images/embedded-tech/nxp.png"
import pgIcon from "../../Assets/images/embedded-tech/pg.png"
// import reactIcon from "../../Assets/images/embedded-tech/react.png"
import telitIcon from "../../Assets/images/embedded-tech/telit.png"
import binanceIcon from "../../Assets/images/Blockchain-tech/binance.png"
import Blockchain001Icon from "../../Assets/images/Blockchain-tech/Blockchain001.png"
import defiIcon from "../../Assets/images/Blockchain-tech/defi.png"
import dexIcon from "../../Assets/images/Blockchain-tech/dex.png"
import fantomIcon from "../../Assets/images/Blockchain-tech/fantom.png"
import hardhatIcon from "../../Assets/images/Blockchain-tech/hardhat.png"
import Blockchain002Icon from "../../Assets/images/Blockchain-tech/Blockchain002.png"
import remixIcon from "../../Assets/images/Blockchain-tech/remix.png"
import solanaIcon from "../../Assets/images/Blockchain-tech/solana.png"
import solidityIcon from "../../Assets/images/Blockchain-tech/solidity.png"
import terraIcon from "../../Assets/images/Blockchain-tech/terra.png"
import thereurIcon from "../../Assets/images/Blockchain-tech/thereur.png"
import truffleIcon from "../../Assets/images/Blockchain-tech/truffle.png"
import uiux001 from "../../Assets/images/ui&ux-tech/ui-ux001.png"
import uiux002 from "../../Assets/images/ui&ux-tech/ui-ux002.png"
import uiux003 from "../../Assets/images/ui&ux-tech/ui-ux003.png"
import uiux004 from "../../Assets/images/ui&ux-tech/ui-ux004.png"
import uiux005 from "../../Assets/images/ui&ux-tech/ui-ux005.png"
import uiux006 from "../../Assets/images/ui&ux-tech/ui-ux006.png"
import uiux007 from "../../Assets/images/ui&ux-tech/ui-ux007.png"
import uiux008 from "../../Assets/images/ui&ux-tech/ui-ux008.png"
import uiux009 from "../../Assets/images/ui&ux-tech/ui-ux009.png"
import uiux0010 from "../../Assets/images/ui&ux-tech/ui-ux0010.png"
import uiux0011 from "../../Assets/images/ui&ux-tech/ui-ux0011.png"
import uiux0012 from "../../Assets/images/ui&ux-tech/ui-ux0012.png"
import uiux0013 from "../../Assets/images/ui&ux-tech/ui-ux0013.png"
import aiml001 from "../../Assets/images/ai-ml-tech/aiml001.png"
import aiml002 from "../../Assets/images/ai-ml-tech/aiml002.png"
import aiml003 from "../../Assets/images/ai-ml-tech/aiml003.png"
import aiml004 from "../../Assets/images/ai-ml-tech/aiml004.png"
import aiml005 from "../../Assets/images/ai-ml-tech/aiml005.png"
import aiml006 from "../../Assets/images/ai-ml-tech/aiml006.png"
import aiml007 from "../../Assets/images/ai-ml-tech/aiml007.png"
import aiml008 from "../../Assets/images/ai-ml-tech/aiml008.png"
import aiml009 from "../../Assets/images/ai-ml-tech/aiml009.png"
import aiml0010 from "../../Assets/images/ai-ml-tech/aiml0010.png"
export const webStack = [
    {
        name: "React",
        url: reactIcon
    },
    {
        name: "Angular",
        url: angularIcon
    },
    {
        name: "vue",
        url: vueIcon
    },
    {
        name: "python",
        url: pythonIcon
    },
    {
        name: "django",
        url: djangoIcon
    },
    {
        name: "mongodb",
        url: mongodbIcon
    },
    {
        name: "html",
        url: htmlIcon
    },
    {
        name: "asp",
        url: aspIcon
    },
    {
        name: "ajax",
        url: ajaxIcon
    },
    {
        name: "laravel",
        url: laravelIcon
    },
    {
        name: "js",
        url: jsIcon
    },
    {
        name: "js1",
        url: js1Icon
    },
    {
        name: "php",
        url: phpIcon
    },
]

export const mobileStack = [
    {
        name: "android",
        url: androidIcon,
    },
    {
        name: "ios",
        url: iosIcon,
    },
    {
        name: "kotlin",
        url: kotlinIcon,
    },
    {
        name: "flutter",
        url: flutterIcon,
    },
    {
        name: "react-native",
        url: reactnativeIcon,
    },
    {
        name: "swift",
        url: swiftIcon,
    },
    {
        name: "xml",
        url: xmlIcon,
    },

]

export const embeddedStack = [
    {
        name: "accenture",
        url: accentureIcon,
    },
    {
        name: "analog",
        url: analogIcon,
    },
    {
        name: "android",
        url: androidIcon,
    },
    {
        name: "arm",
        url: armIcon,
    },
    {
        name: "att",
        url: attIcon,
    },
    {
        name: "augmented",
        url: augmentedIcon,
    },
    {
        name: "aws",
        url: awsIcon,
    },
    {
        name: "azure",
        url: azureIcon,
    },
    {
        name: "bosch",
        url: boschIcon,
    },
    {
        name: "drive",
        url: driveIcon,
    },
    {
        name: "nordic",
        url: nordicIcon,
    },
    {
        name: "nxp",
        url: nxpIcon,
    },
    {
        name: "pg",
        url: pgIcon,
    },
    {
        name: "react",
        url: reactIcon,
    },
    {
        name: "telit",
        url: telitIcon,
    },
]

export const BlockchainStack = [
    {
        name: "binance",
        url: binanceIcon,
    },
    {
        name: "Blockchain001",
        url: Blockchain001Icon,
    },
    {
        name: "defi",
        url: defiIcon,
    },
    {
        name: "dex",
        url: dexIcon,
    },
    {
        name: "fantom",
        url: fantomIcon,
    },
    {
        name: "hardhat",
        url: hardhatIcon,
    },
    {
        name: "Blockchain002",
        url: Blockchain002Icon,
    },
    {
        name: "remix",
        url: remixIcon,
    },
    {
        name: "solana",
        url: solanaIcon,
    },
    {
        name: "solidity",
        url: solidityIcon,
    },
    {
        name: "terra",
        url: terraIcon,
    },
    {
        name: "thereur",
        url: thereurIcon,
    },
    {
        name: "truffle",
        url: truffleIcon,
    }
]
export const UiandUxStack = [
    {
        name: "uiux001",
        url: uiux001,
    },
    {
        name: "uiux002",
        url: uiux002,
    },
    {
        name: "uiux003",
        url: uiux003,
    },
    {
        name: "uiux004",
        url: uiux004,
    },
    {
        name: "uiux005",
        url: uiux005,
    },
    {
        name: "uiux006",
        url: uiux006,
    },
    {
        name: "uiux007",
        url: uiux007,
    },
    {
        name: "uiux008",
        url: uiux008,
    },
    {
        name: "uiux009",
        url: uiux009,
    },
    {
        name: "uiux0010",
        url: uiux0010,
    },
    {
        name: "uiux0011",
        url: uiux0011,
    },
    {
        name: "uiux0012",
        url: uiux0012,
    },
    {
        name: "uiux0013",
        url: uiux0013,
    }
]
export const AiandMlStack = [
    {
        name: "aiml001",
        url: aiml001,
    },
    {
        name: "aiml002",
        url: aiml002,
    },
    {
        name: "aiml003",
        url: aiml003,
    },
    {
        name: "aiml004",
        url: aiml004,
    },
    {
        name: "aiml005",
        url: aiml005,
    },
    {
        name: "aiml006",
        url: aiml006,
    },
    {
        name: "aiml007",
        url: aiml007,
    },
    {
        name: "aiml008",
        url: aiml008,
    },
    {
        name: "aiml009",
        url: aiml009,
    },
    {
        name: "aiml0010",
        url: aiml0010,
    }
]