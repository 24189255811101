/**
 * @author
 * @version 1.0
 */

import React from "react";
import { Image } from "react-bootstrap";

import { Link, useMatch, useResolvedPath } from "react-router-dom";

import logo from "../../Assets/images/company-logo.png";

import "./topbar.styled.scss";

export default function Topbar({ menuOpen, setMenuOpen, hasTitle,hideCD }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <nav className="nav">
        <Link to="/" className="site-title">
          <Image className="header-image" fluid src={logo} alt="" />
        </Link>
        <ul>
          <CustomLink  onClick={() => {setMenuOpen(true); if(hideCD){hideCD()} }}>
            <div className="hamburger">
              <div className="bar one"></div>
              <div className="bar two"></div>
              <div className="bar three"></div>
            </div>
          </CustomLink>
        </ul>
      </nav>
    </div>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
