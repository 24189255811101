/**
 * @author Nadir H. & Nabeel Yousaf
 * @version 1.0
 */

import React from "react";
import { Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  webStack,
  mobileStack,
  embeddedStack,
  BlockchainStack,
  UiandUxStack,
  AiandMlStack,
} from "./TechImagesArray";
import TechStack from "./TechStack";
import "./styles.scss";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      key={index}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TechnologyTab() {
  const [value, setValue] = React.useState(0);
  const [techArray, setTechArray] = React.useState(webStack);

  const techs = [
    {
      label: "Web & App",
      description:
        "Silicon Nexus specializes in web applications. We have a team of highly skilled developers who are experts in their field. Our web services are used by some of the biggest companies in the world. We are constantly innovating and expanding our product offerings, and we are always on the cutting edge of new technology. ",
    },
    {
      label: "Mobile App",
      description:
        "Silicon Nexus is a leading provider of mobile application development services. With a team of highly skilled and experienced developers, we have the ability to create customized applications that meet the specific needs of our clients. We understand the importance of having a robust and user-friendly mobile application, and we are dedicated to providing our clients with the best possible service. We have a proven track record in delivering high-quality mobile applications, and we are confident that we can exceed your expectations.",
    },
    {
      label: "Embedded Systems",
      description:
        "Silicon Nexus is a leading embedded software solution provider. Our Services are used in a wide range of industries that include automotive, aerospace, consumer electronics and industrial automation.Having a long history of innovation we continue to push the boundaries, extending our skillset spectrum on a regular basis.",
    },
    {
      label: "Blockchain",
      description:
        "We are excited to be at the forefront of blockchain technology. Our dedicated team is always available to help businesses harness the power of revolutionary technology. With our blockchain platform, businesses can securely and efficiently track and manage their assets and transactions. We believe that blockchain has the potential to transform the way businesses operate and we are committed to helping our clients realize that potential. ",
    },
    {
      label: "UI/UX Design",
      description:
        "Our creativity is always on top in the field of UI/UX design. We have a team of experienced designers who are passionate about creating beautiful and user-friendly interfaces. Our goal is to help businesses achieve their desired results by providing them with the best possible user experience. ",
    },
    {
      label: "AI/ML Development",
      description:
        "We are amongst the leading artificial intelligence/machine learning solution providers. Our team is passionate about creating innovative solutions that will help shape the future of AI/ML. We are constantly researching and experimenting with new ways to improve our algorithms and software. We believe that by harnessing the power of AI/ML, we can make a positive impact on the world.",
    },
  ];

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      setTechArray(webStack);
    } else if (newValue == 1) {
      setTechArray(mobileStack);
    } else if (newValue == 2) {
      setTechArray(embeddedStack);
    } else if (newValue == 3) {
      setTechArray(BlockchainStack);
    } else if (newValue == 4) {
      setTechArray(UiandUxStack);
    } else if (newValue == 5) {
      setTechArray(AiandMlStack);
    }
    setValue(newValue);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className="techstack-section-container">
      <div style={{ maxWidth: "90vw" }}>
        <div className="heading-container mb-5">
          <p
            style={{
              fontFamily: "'Poppins'",
              fontSyle: "normal",
              fontSize: "42px",
              fontWeight: "700",
              color: "#000000;",
              textAlign: "center",
            }}
          >
            Our Technology <span style={{ color: "#1480F3" }}>Stack</span>
          </p>
        </div>

        <Box
          sx={{
            borderBottom: 1,
            borderTop: 1,
            borderColor: "divider",
            marginTop: "1rem",
          }}
          display="flex"
          justifyContent={"center"}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            centered
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#1480F3",
                height: 5,
              },
            }}
          >
            {techs.map((tech, index) => {
              return (
                <Tab
                  style={{
                    fontSize: isMobile ? "12px" : "18px",
                  }}
                  label={tech.label}
                  {...a11yProps(index)}
                  key={index}
                  className="tech-stack-tab-heading"
                />
              );
            })}
          </Tabs>
        </Box>
        {isMobile && (
          <Box >
            <Grid container className="center-aligned-images" >
              {techArray?.map((icon, index) => (
                <Grid
                  item
                  xs={
                    techArray.length / 2 == 0 && techArray.length - 1 === index
                      ? 12
                      : 4
                  }
                  sm={
                    techArray.length / 2 == 0 && techArray.length - 1 === index
                      ? 12
                      : 4
                  }
                  md={12}
                  lg={12}
                  className="tech-stack-image-item-grid"
                >
                  <div className="tech-stack-icon-container">
                    <img
                      src={icon.url}
                      style={{
                        width: "75px",
                        height: "70px",
                      }}
                      alt=""
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {!isMobile && (
          <Box>
            {techs.map((tech, index) => {
              return (
                <TabPanel
                  value={value}
                  index={index}
                  sx={{ margin: 0, padding: 0, backgroundColor: "aqua" }}
                >
                  <TechStack
                    stackId={index}
                    stackName={tech.label}
                    description={tech.description}
                  />
                </TabPanel>
              );
            })}
          </Box>
        )}
      </div>
    </Box>
  );
}

TechnologyTab.prototype = {
  children: React.ReactNode,
  index: PropTypes.number,
  value: PropTypes.number,
};
