/**
 * @author
 * @version 1.0
 */

import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import MuiLink from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import footer001 from "../../Assets/images/footer001.png";
import footer002 from "../../Assets/images/footer002.png";
import footer003 from "../../Assets/images/footer003.png";
import footer004 from "../../Assets/images/footer004.png";
import footerSilicon from "../../Assets/images/footerSilicon.png";

import "./styles.scss";
import { Image } from "react-bootstrap";
import FooterBox from "../FooterBox/FooterBox";

export default function Footer({ style }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));

  const ColorStyle = {
    color: "#1480F3",
  };

  const FontMenuStyle = {
    fontSize: isMobile ? "12px" : "15px",
    color: "#ffffff",
  };
  const FontHeadingStyle = {
    fontSize: isMobile ? "15px" : "18px",
    color: "#1480F3",
  };
  const socialIconStyle = {
    fontSize: isMobile ? "18px" : "1.5rem",
    color: "#1480F3",
  };
  const socialIconMargin = {
    marginLeft: "3px",
  };

  const handleEmailClick = () => {
    // Open Gmail compose window
    window.location.href = 'https://mail.google.com/mail/?view=cm&fs=1&to=contact@silicon-nexus.com';
  };
   const handlePhoneClick = (phoneNumber) => {
    // Redirect to WhatsApp
    window.location.href = `https://wa.me/${phoneNumber}`;
  };
  return (
    <Box className="main-footer-container" pt={4}>
      <div className="footer-child-container"
     
      >
        <FooterBox
          icon={footer001}
          heading={"Contact"}
          primaryText={
            <a
              href="tel:+13025264003"
              onClick={(e) => {
                e.preventDefault();
                handlePhoneClick("13025264003");
              }}
            >
              +1 302 526 4003
            </a>
          }
          secondaryText={
            <a
              href="tel:+923466670738"
              onClick={(e) => {
                e.preventDefault();
                handlePhoneClick("923466670738");
              }}
            >
              +92 346 6670738
            </a>
          }
          link={
            <Link to="/contact-us" className="underline-text">
          contact us
            </Link>
          }
        />
        <FooterBox
          icon={footer004}
          heading={"Locations"}
          primaryText={"8 The Green, Suite R, Dover,"}
          secondaryText={"Delaware - 19901"}
          link={
            <Link to="/contact-us" className="underline-text">
             View Locations
            </Link>
          }
        
        />
         <FooterBox
          icon={footer003}
          heading={"Sales"}
          primaryText={
            <MuiLink
              color="inherit"
              href={`mailto:sales@silicon-nexus.com`}
              onClick={(e) => {
                e.preventDefault();
                handleEmailClick("sales@silicon-nexus.com");
              }}
            >
              sales@silicon-nexus.com
            </MuiLink>
          }
          secondaryText={
            <MuiLink
              color="inherit"
              href={`mailto:contact@silicon-nexus.com`}
              onClick={(e) => {
                e.preventDefault();
                handleEmailClick("contact@silicon-nexus.com");
              }}
            >
              contact@silicon-nexus.com
            </MuiLink>
          }
          link={
            <Link to="/contact-us" className="underline-text">
              Keep In Touch
            </Link>
          }
        />
        <FooterBox
          icon={footer002}
          primaryText={"We are here to help with any"}
          secondaryText={"questions or code"}
          heading={"Support"}
          link={
            <Link to="/contact-us" className="underline-text">
             Help & Support
            </Link>
          }
        
        />
      </div>
      <Box className="footer-section-container">
        <Grid container spacing={3} pb={4}>
        {(!isMobile ) && (
          <>
             <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            pr={isMobile || isLaptop || isTablet ? 0 : 10}
          >
            <MuiLink color="inherit" href={"/"} className="parent-link">
              <Image
                src={footerSilicon}
                alt=""
                fluid
                className=" img-responsive"
                loading="lazy"
              />
            </MuiLink>

            <Typography
              className="small-font"
              sx={{ fontSize: isMobile ? "12px !important" : "15px" }}
            >
              We excel in visualization of ground breaking ideas and
              transforming them to reality. With the challenging analytical
              skills, we empower the technical resources and help our clients.
            </Typography>
          </Grid>
          </>
        )}
         

          <Grid
            item
            xs={7}
            sm={7}
            md={3}
            lg={3}
            xl={3}
            pl={isMobile || isTablet || isLaptop ? 0 : "4rem !important"}
          >
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              SERVICES
            </h6>
            <hr className="hr-line-footer-heading" style={ColorStyle} />
            <p style={FontMenuStyle}>
              <Link
                to="/service/mobile-design-service"
                className=" underline-text"
              >
                Mobile App development
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link
                to="/service/block-chain-service"
                className=" underline-text"
              >
                Blockchain development
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/service/ai-mi-service" className=" underline-text">
                AI and Ml Development
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/service/embedded-service" className=" underline-text">
                Embedded Systems
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link
                to="/service/ui-ux-design-service"
                className=" underline-text"
              >
                UI/UX Design
              </Link>
            </p>
          </Grid>

          <Grid item xs={5} sm={5} md={3} lg={3} xl={2}>
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              USEFUL LINKS
            </h6>
            <hr className="hr-line-footer-heading" style={ColorStyle} />
            <p style={FontMenuStyle}>
              <Link to="/contact-us" className=" underline-text">
                Contact Us
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/about-us" className=" underline-text">
                About Us
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/career" className=" underline-text">
                Careers
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/staff-augmentation" className=" underline-text">
                Staff Augmentation
              </Link>
            </p>

          
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={4}>
            <h6 className=" mb-3  footer-text-heading custom-upper-padding" style={FontHeadingStyle}>
              CONTACT
            </h6>
            <hr className="hr-line-footer-heading" style={ColorStyle} />
            <p className=" " style={FontMenuStyle}>
              <i className="fas fa-home me-3" style={ColorStyle}></i>8 The Green,
              Suite R, Dover, Delaware - 19901
            </p>
            <p className=" " style={FontMenuStyle}>
              <i className="fas fa-home me-3" style={ColorStyle}></i>
              Office- FF47, New liberty plaza, Model town link road, Lahore.
            </p>
             <p className="" style={FontMenuStyle}>
              <i className="fas fa-envelope me-3" style={ColorStyle}></i>{" "}
              <MuiLink
              color="inherit"
              href={`mailto:contact@silicon-nexus.com`}
              onClick={(e) => {
                e.preventDefault();
                handleEmailClick("contact@silicon-nexus.com");
              }}
            >
              contact@silicon-nexus.com
            </MuiLink>
            </p>
            <p className="" style={FontMenuStyle}>
              <i className="fas fa-phone me-3" style={ColorStyle}></i>
           
            <a
              href="tel:+13025264003"
              onClick={(e) => {
                e.preventDefault();
                handlePhoneClick("13025264003");
              }}
            >
              +1 302 526 4003
            </a>
         
            </p>

            <ul
              className="list-unstyled d-flex  social-circle-icons"
              style={{ marginLeft: "-6px" }}
            >
              <li className="px-2 text-center social-icon">
                <a
                  href="https://www.linkedin.com/company/siliconnexus/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-linkedin social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>

              <li
                className="px-2 text-center social-icon"
                style={socialIconMargin}
              >
                <a
                  href="https://mobile.twitter.com/nexussilicon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-twitter social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
              <li
                className="px-2 text-center social-icon"
                style={socialIconMargin}
              >
                <a
                  href="https://www.instagram.com/silicon_nexus/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-instagram social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
              <li
                className="px-2 align-items-center text-center social-icon"
                style={socialIconMargin}
              >
                <a
                  href="https://www.facebook.com/siliconnexus/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-facebook social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <div
          className="text-center  p-3"
         
          style={{ color: "#F8F9FB" }}
        >
          © 2022 Copyright :
          <Link className="" to="https://silicon-nexus.com/">
            SiliconNexus
          </Link>
        </div>
      </Box>
    </Box>
  );
}