import { Box, Grid } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import "./styles.scss";
import Menu from "../Menu/Menu";
import { useState } from "react";
import TextWithTwoColors from "../TextWithTwoColors/TextWithTwoColors";
import PageLinks from "../Reusables/PageLinks";
import Topbar from "../Topbar";

export default function Banner({
  imag,
  firstWord,
  secondWord,
  description,
  path,
  current,
  showCD,
  hideCD,
  mergeFirst,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Box
      className="banner-container-main"
      height={isMobile|| isTablet ? "450px" : "550px"}
    >
      <div
        className="banner-container"
        style={{
          height: isMobile || isTablet ? "450px" : "550px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "1000px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextWithTwoColors
            firstColor={"#ffffff"}
            mergeFirstColor={"#1480F3"}
            secondColor={"#1480F3"}
            firstWord={firstWord}
            mergeFirst={mergeFirst}
            secondWord={secondWord}
            description={description}
            descriptionColor={"#ffffff"}
          />
          <div className="pagination-container">
            <PageLinks current={current} path={path} />
          </div>
        </div>
     
      </div>
      <div className="menu-container">
        {!menuOpen ? (
          <>
            <Topbar
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              hideCD={hideCD}
            />
          </>
        ) : (
          <>
            <Menu
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              showCD={showCD}
            />
          </>
        )}
      </div>
    </Box>
  );
}
