/**
 * @author Nadir
 * @version 1.0
 */
import axios from "axios";
// const BASE_URL = "http://localhost:4000/api";
const BASE_URL = "https://si-nexus-back-end.vercel.app/api";

const apiPOST = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'Content-Type': 'multipart/form-data',
    },
    body: JSON.stringify(body),
    // body: body
  });

  return await res.json();
};
const apiFileFormPost = async (api, body) => {
  const res = await axios({
    method: "POST",
    url: `${BASE_URL}/${api}`,
    data: body,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log("Response", res.data)
  return await res.data;
};

const apiGET = async (api) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await res.json();
};

const apiGETById = async (api, id) => {
  const res = await fetch(`${BASE_URL}/${api}/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await res.json();
};

const apiPUT = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return await res.json();
};

const apiPATCH = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};

const apiDELETE = async (api, body) => {
  const res = await fetch(`${BASE_URL}/${api}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return await res.json();
};

export { apiPOST, apiGET, apiPATCH, apiDELETE, apiPUT, apiFileFormPost, apiGETById };
