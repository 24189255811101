import React from "react";
import "./Choose.scss";
import industry from "../../Assets/images/industry-.png";
import group1 from "../../Assets/images/Group1.png";
import group2 from "../../Assets/images/Group2.png";
import group3 from "../../Assets/images/Group3.png";
import group4 from "../../Assets/images/Group4.png";
import group5 from "../../Assets/images/Group 5.png";
import { Grid } from "@mui/material";



const Choose = () => {
    return (
        <div className="main ">
            <Grid container className="why-choose-us-heading-and-para">
                <Grid sx={12} sm={12} md={12} lg={12}>
                    <div className="content">
                        <h1 className="heading">Why <span style={{ color: "#1480F3" }}>Choose us</span></h1>
                        <p className="para">Modern Approach & Sustainable Results</p>
                    </div>
                </Grid>
                <div className="container">
                    {/* <div className="card-container"> */}
                        <Grid container spacing={2} className="card-container">
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={industry} />
                                        <p style={{ marginTop: "10px", marginLeft: "0px" }}>Top industry specialists</p>
                                    </div>
                                    <div class="card-body">
                                        <p>With many years of experience and a deep understanding of the subject, we are professionals in the industry.</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={group1} />
                                        <p style={{ marginTop: "10px", marginLeft: "5px" }}>24/7 Customer support</p>
                                    </div>
                                    <div class="card-body">
                                        <p>Customers have access to help and answers to questions 24 hours a day, 7 days a week, and in real time</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={group2} />
                                        <p style={{ marginTop: "10px", marginLeft: "5px" }}>Fixed fees </p>
                                    </div>
                                    <div class="card-body">
                                        <p>At the start of the contract, an agreed upon amount is fixed.</p>
                                    </div>
                                </div>
                            </Grid>
                            </Grid>
                            <Grid container spacing={2} className="card-container" style={{marginTop:"3px"}}>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={group3} />
                                        <p style={{ marginTop: "10px", marginLeft: "5px" }}>Customer focus</p>
                                    </div>
                                    <div class="card-body">
                                        <p>Putting customer at top priority in our business decisions</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={group4} />
                                        <p style={{ marginTop: "10px", marginLeft: "5px", }}>100% Money back guarantee</p>
                                    </div>
                                    <div class="card-body">
                                        <p>If you are not satisfied with the product or service, we will refund the full amount.</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <div class="card">
                                    <div class="card-header">
                                        <img src={group5} />
                                        <p style={{ marginTop: "10px", marginLeft: "5px" }}>Tailored solutions</p>
                                    </div>
                                    <div class="card-body">
                                        <p>Using our development services, we can customize solutions to meet your company needs.</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    {/* </div> */}
                </div>
            </Grid>
        </div>
    );
};

export default Choose;

