import React, { useState } from "react";
import "./ClientReview.scss";

//replacing of dummy images by hamza Mansoor
import reviewimg from "../../Assets/images/dummy-image.png";
import reviewimg2 from "../../Assets/images/dummy-image.png";
import reviewimg3 from "../../Assets/images/dummy-image.png";


import rating from "../../Assets/images/Rate.png";
import sideIcon from "../../Assets/images/review-side-icon.png";
import prevIcon from "../../Assets/images/prevIcon.png";
import nextIcon from "../../Assets/images/nextIcon.png";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const ClientReview = () => {
  const slides = [
    {
      id: 1,
      img: reviewimg,
      name: "Henry Gong",
      title: "CTO at Roobuck",
      review:'',
        description:
        "Working with Silicon Nexus was a pleasure. They are very knowledgeable about PCBs and electronics and very patient when working with us. We made a change in specifications in the middle of the project, but even then they handled it without a problem. The team of Silicon Nexus would consider factors we overlooked, which ultimately resulted in better final product. We appreciate the knowledge and forbearance of Silicon Nexus, and look forward to work with them in the future.",
    },
    {
      id: 2,
      img: reviewimg2,
      name: "Aqib Usman",
      title: "CTO at Lightpong",
      review:'',
      description:
        "When it comes to web design and development, the team of Silicon Nexus is simply unmatched. As a client review speaks for themselves - they are consistently rated as one of the top web design and development firms in the country. What sets them apart is their attention to detail, their passion for their work, and their willingness to go above and beyond for their clients. They truly care about each and every project they work on, no matter how big or small. If you are looking for a web design and development firm that will treat your project with the care and attention it deserves, then Silicon Nexus is the team for you. You won't be disappointed!",
    },
    {
      id: 3,
      img: reviewimg3,
      name: "Michael Platt",
      title: "CEO at Qualcomn Vendor",
      review:'',
        description:
        `Silicon Nexus team is composed of experienced and dedicated professionals who are passionate about their work. They go above and beyond for their clients, and it shows in the reviews. Silicon Nexus team was a pleasure to work with. They were very responsive to our needs and provided great insights throughout the project.`,
    },
  
  ];

  const [seeMore,setSeeMore] = useState(slides.map((slides,index)=>index))
  
  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,

    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1044:{
      slidesPerView: 3,
      spaceBetween: 20,

    }
  };

  const handleSeeMore = (index)=>{
    let present = seeMore.filter(item=>item==index)
    
    if(present.length>0){
      setSeeMore(seeMore.filter(item=>item!=index))
    }
    else{
      setSeeMore((pre,)=>([...pre,index]))
    }
  }


  return (
    <>
      <div className="client-reviews-container">
        <div className="client-reviews-heading">Client <span style={{color:"#1480F3"}}>Reviews</span></div>
        <div className="client-reviews-para" >
          
          We are proud to be recognized by some of the most prestigious and influential technology organizations in the world.

          </div>
        <div className="client-reviews" 
        style={{ padding: "40px 150px 0px ",}}
         >
          <Swiper
            cssMode={true}
            spaceBetween={30}
            breakpoints={breakpoints}
            navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev",customClass: 'custom-swiper-nav', }}
            pagination={{ clickable: true }}
            className="mySwiper"

          >
            {slides.map((slide,index) => {
              
              return (
              <SwiperSlide key={slide.id}>
                <div className="reviews-cards" >
                  <div className="reviews-cards-section1">
                    <div><img src={slide.img} alt={slide.title} /></div>
                    <div className="reviews-cards-section1-right">
                      <p className="reviews-client-name">{slide.name}</p>
                      <p className="reviews-client-title">{slide.title}</p>
                    </div>
                  </div>
                  <div className="reviews-cards-section2">
                    <p className="reviews-client-review">{slide.review}</p>
                    <p className="reviews-client-description">{(seeMore.includes(index)) ? slide.description.substring(0,160)+"..." : slide.description}
                    <span style={{cursor:'pointer',color:'#1480F3'}} onClick={()=>{handleSeeMore(index)}}>{seeMore.includes(index) ? " see more" : " see less"}</span>
                    </p>
                    <img src={rating} alt="reviews" />
                  </div>
                  <img className="reviews-cards-icon" src={sideIcon} />
                </div>
              </SwiperSlide>
            )})}
           
            <img className="swiper-button-prev" src={prevIcon} style={{width:"45px",height:"45px", left: '0px' }} />
            <img className="swiper-button-next" src={nextIcon} style={{width:"45px",height:"45px", right: '0px' }} />
            
          </Swiper>
        </div>
      </div>
    </>

  );
};

export default ClientReview;
