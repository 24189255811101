import React from "react";
import "./styles.scss";
import image001 from "../../Assets/images/image001.png";
import image002 from "../../Assets/images/image002.png";
import image003 from "../../Assets/images/image003.png";
import image004 from "../../Assets/images/image004.png";
import image005 from "../../Assets/images/image005.png";
import image006 from "../../Assets/images/image006.png";
import image007 from "../../Assets/images/image007.png";
import image008 from "../../Assets/images/image008.png";
import TextWithTwoColors from "../TextWithTwoColors/TextWithTwoColors";

const COLORS = [
  "#bbf7d0",
  "#99f6e4",
  "#bfdbfe",
  "#ddd6fe",
  "#f5d0fe",
  "#fed7aa",
  "#fee2e2",
];
const TAGS = [image001, image002, image003, image003, image004, image005, image006, image007, image008];
const DURATION = 100000;
const ROWS = 1;
const TAGS_PER_ROW = 50;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({ img }) => {
  
  return (
    <div>
      
      <img src={img} alt="" className="award-image" />
    </div>
  );
};

const AwardCarousal = () => (
  <div className="award-container">
    <TextWithTwoColors 
    firstColor={"#000000"}
    secondColor={"#1480F3"}
    firstWord={"Awards &"}
    secondWord={"Recognition"}
    description={"Silicon-Nexus is proud to be recognized by the most important and influential technology organizations around the world."}
    descriptionColor={"#000000"}
    />
    <div className="tag-list" >
      {[...new Array(ROWS)].map((_, i) => (
        <InfiniteLoopSlider
          key={i}
          duration={random(DURATION - 5000, DURATION + 5000)}
          reverse={i % 2}
        >
          {shuffle(TAGS)
           
            .map((tag, index) => (
              <Tag img={tag} key={index} />
            ))}
        </InfiniteLoopSlider>
      ))}
      
    </div>
  </div>
);

export default AwardCarousal;
