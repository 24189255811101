/**
 * @author Nadir H.
 * @version 1.0
 */

import React from "react";
import { Box, Grid } from "@mui/material";
import StackElements from "./StackElements";

export default function TechStack({ stackId, stackName, description }) {
  return (
    <Box mt={2}>
      <Grid container className="tech-stack-details-container">
        <Grid item xs={0} sm={0} md={0} lg={0.5} xl={0.5}></Grid>
        <Grid   item xs={12} sm={12} md={12} lg={12} xl={12}>
          <StackElements stackId={stackId} stackName={stackName} />
        </Grid>

       
      </Grid>
    </Box>
  );
}
