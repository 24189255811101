import React from "react";
import "./styles.scss";

const BlueButton = ({ title, type, onClick }) => {
  return (
    <button type={type} className="blue-btn-with-text" onClick={onClick}>
      <span onClick={onClick}>{title}</span>
      <span onClick={onClick}>{">"}</span>
    </button>
  );
};

export default BlueButton;
