import React from "react";
import "./styles.scss";

const TextWithTwoColors = ({
  firstWord,
  secondWord,
  thirdWord,
  firstColor,
  secondColor,
  thirdColor,
  description,
  descriptionColor,
  mergeFirst,
  mergeFirstColor,
}) => {
  return (
    <div className="container">
      <div className="heading-text">
        <span style={{ color: firstColor }}>{firstWord}<span style={{color:mergeFirstColor}} >{mergeFirst}</span>
        &nbsp;
        <span style={{ color: secondColor }}>{secondWord}
        &nbsp;
        <span style={{ color: thirdColor }}>{thirdWord}</span></span></span>
      </div>
      <p className="description-text" style={{ color: descriptionColor }}>
        {description}
      </p>
    </div>
  );
};

export default TextWithTwoColors;
