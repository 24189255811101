import React, { useEffect, useState } from "react";

import ServiceCard from "../Components/Services/ServiceCard";
import TechnologyTab from "../Components/TecnologyTab/TechnologyTab";
import ChooseUsCard1 from "../Components/ChooseUsCard1/ChooseUsCard1";
import Footer from "../Components/Footer/Footer";
import MainHeader from "../Components/Header/MainHeader";
import Introduction from "../Components/Introduction/Introduction";
import NewEngagementCard from "../Components/NewEngagementCard/NewEngagementCard";
import Loader from "../Components/Loader/Loader";
import Choose from "../Components/WhyChoose/Choose";
import AwardCarousal from "../Components/Award/AwardCarousal";
import EngagementModel from "../Components/EngagementModel/EngagementModel";
import ClientReview from "../Components/ClientReview/ClientReview";
// import { Helmet } from "react-helmet-async";
export default function Home() {
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 2500);
  }, [spinner]);
  const [contactDisplay, setContactDisplay] = useState(true);

  const showCD = () => {
    setContactDisplay(true);
  };
  const hideCD = () => {
    setContactDisplay(false);
  };

  return spinner === false ? (
    <div>
      {/* <Helmet>
  <title>Home</title>
  <meta name="description" content="here is Home page "/>
  <link rel="canonical" href="/"></link>
</Helmet> */}
      <MainHeader showCD={showCD} hideCD={hideCD} />
      <ServiceCard></ServiceCard>
      
      <div style={{ display: contactDisplay ? "block" : "none" ,overflowX:"hidden" }}>
        <ChooseUsCard1 />
        <Choose />
        <TechnologyTab />
        <EngagementModel /> 
        <ClientReview /> 
        <AwardCarousal />
      </div>
      <Footer />
    </div>
  ) : (
    <Loader />
  );
}
