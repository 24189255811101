/**
 * @author Haris
 * @version 1.0
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import web from "../../lottie/web.json";
import blockchain from "../../lottie/blockchain-development.json";
import ui from "../../lottie/ui.json";
import ai from "../../lottie/ai.json";
import mobile from "../../lottie/mobile.json";
import embedded from "../../lottie/embedded.json";

import LottiePlayer from "../Reusables/LottiePlayer";
import { Box, Grid, Typography } from "@mui/material";
import image1 from "../../Assets/images/web-system.png";
import image2 from "../../Assets/images/embedded-system.png";
import vector from "../../Assets/images/greater-than-vector.png"

import Slide from '@mui/material/Slide';

//styles
import "./styles.scss";

export default function ServiceCard() {
  const services = [
    {
      img: image1,
      id: 1,
      heading: "Web Application Development",
      // details: "Silicon Nexus specializes in web applications. They...",
      details: "We specializes in web applications and have a ...",
      lottie: web,
      link: "/service/web-design-service",
    },
    {
      img: image2,
      id: 0,
      heading: "Embedded System Development",
      details: "As a leading embedded software solutions. Our prod...",
      // details: "With a team of highly skilled and experienced mobile...",
      lottie: embedded,
      link: "/service/embedded-service",
    },
    {
      id: 2,
      heading: "Mobile Application Development",
      // details: "Silicon Nexus is a leading provider of mobile applicat...",
      details: "With a team of highly skilled and experienced mobile...",
      lottie: mobile,
      link: "/service/mobile-design-service",
    },
    {
      id: 3,
      heading: "UI/UX & Animation",
      details: "Our creativity is always on top in the field of UI/UX design...",
      // details: "In Blockchain Technology our dedicated team is to help...",
      lottie: ui,
      link: "/service/ui-ux-design-service",
    },
    {
      id: 4,
      heading: "Blockchain & Web3.0 Development",
      // details: "Silicon Nexus is excited to be at the forefront of blockcha...",
      details: "In Blockchain Technology our dedicated team is to help...",
      lottie: blockchain,
      link: "/service/block-chain-service",
    },
    {
      id: 5,
      heading: "AI & ML Software Development",
      // details: "Silicon Nexus is also excited to be at the forefront of dev...",
      details: "We are excited to be at the forefront of developing new...",
      lottie: ai,
      link: "/service/ai-mi-service",
    },
  ];

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initial,setInitial] = useState(true)

  const mouseEnter = ()=>{
    setInitial(false)
  }
  const mouseLeave = ()=>{
    setInitial(true)
  }

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1000}>
      <Box className="services-section-container" >

        <Grid container className="service-container" mt={isMobile ? -2 : 0} style={{ width: isMobile ? "95%" : "60%" }}  >
          
              <Grid 
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[0]}  mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
              </Grid>
              <Grid
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[1]} initial={initial}  />
              </Grid>
              <Grid
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[2]}   mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
              </Grid>
              <Grid
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[3]} mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
              </Grid>
              <Grid
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[4]}  mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
              </Grid>
              <Grid
                style={{ height: isMobile ? "320px" : "364px" }} item xs={12} sm={6} md={6} lg={4} xl={4} mt={isMobile ? 0 : 0} >
                  <Card service={services[5]}  mouseEnter={mouseEnter} mouseLeave={mouseLeave} />
              </Grid>



        </Grid>
      </Box>
    </Slide>
  );
}

const Card = ({ service,initial,mouseEnter,mouseLeave }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isHoverd,setIsHovered] = useState(false)
  
  const hoveredBackground = {
    transform: 'scale(45)',
    backgroundColor:'#1480F3',
    content: '',
    position: 'absolute',
    zIndex: '-1',
    bottom: '-16px',
    right: '-16px',
    height: '32px',
    width: '32px',
    borderRadius: '32px',
    transformOrigin: '50% 50%',
    transition: 'transform 0.5s ease-out',
  }
  const unHoverdBackground = {
    transform: 'scale(1)',
    backgroundColor:'#ffffff',
    content: '',
    position: 'absolute',
    zIndex: '-1',
    bottom: '-16px',
    right: '-16px',
    height: '32px',
    width: '32px',
    borderRadius: '32px',
    transformOrigin: '50% 50%',
    transition: 'transform 0.5s ease-out',
  }

  const learnMore = initial || isHoverd ? "#ffffff":"#1480F3"
  const heading = initial || isHoverd ? "#ffffff":"#000000"
  const desc = initial || isHoverd ? "#ffffff":"#696969"
  const backcolor = initial || isHoverd ? hoveredBackground:unHoverdBackground


 

 
  const handleHover = (event) => {

    if(mouseEnter){
      mouseEnter()
      setIsHovered(true)
     
    }
  };

  const handleMouseLeave = (event) => {
    if(mouseLeave){
      mouseLeave()
      setIsHovered(false)
        
    }
      };



  return (
    <Box
      className={"service-card "}
     
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        navigate(service.link);
      }}
      sx={{ padding: isMobile ? "20px 12px" : "10px 40px" }}
    >
    <span style={backcolor}></span>
      <div className="service-card-image-container">
        <LottiePlayer
          height={isMobile ? 80 : 100}
          width={isMobile ? 80 : 100}
          animationData={service.lottie}
        />
      </div>
      <div className="service-card-text-container">
        <Typography
          className="service-card-heading"
          sx={{ fontSize: isMobile ? "18px" : "22px" }}
          style={{color:heading}}

        >
          {service.heading}
        </Typography>
        <Typography
          className="service-card-description"
          sx={{
            fontSize: isMobile ? "12px" : "18px",
            paddingLeft: isMobile ? "0rem" : "1rem",
            paddingRight: isMobile ? "0rem" : "1rem",
            paddingTop: isMobile ? "0rem" : "0.5rem",
            paddingBottom: isMobile ? "2rem" : "1rem",
          }}
          style={{color:desc}}

        >
          {service.details}
          <p className="learn-more" style={{ paddingTop: "25px",color:learnMore}}>Learn More <img src={vector} style={{ paddingLeft: "6px", }} ></img></p>
        </Typography>
      </div>
     
    </Box>
  );
};
