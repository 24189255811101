/**
 * @author Nadir H.
 * @version 1.0
 */

import { Box,  Typography } from "@mui/material";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import we_ensure from "../../Assets/images/weensure.png";
import business from "../../Assets/images/business.png"
import vector from "../../Assets/images/greater-than-vector.png"

import Menu from "../Menu/Menu";
import Topbar from "../Topbar";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import bannerVideo from "../../Assets/videos/banner-video1.mp4"
import BlueButton from "../Reusables/BlueButton";

export default function MainHeader({showCD,hideCD}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [slide, setSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate=useNavigate()

  const handleContactClick=()=>{
    navigate("/contact-us")
  }
  return (
    <>
      <div className="header-container">
        <video src={bannerVideo} autoPlay loop muted/>
        <div className="header-text-container" style={{padding:"10px 12px", display:isMobile?"block":"none"}}>
        <p className="first-text" style={{ textAlign: "center", color: "white", fontSize: "36px", fontWeight: "bold", textTransform:"uppercase" }}>We ensure <span style={{ color: "#1480F3" }}>customer</span> success</p>
        <p className="second-text" style={{ textAlign: "center", color: "#ffffff", fontSize: "20px", fontWeight: 400 }}>Business consultation and innovation partner we analyze, research and propose solutions</p>
        </div>
        <div className="header-btn-container">
        <BlueButton type="link" title={"Let’s Talk Now"} onClick={handleContactClick}/>
        </div>
        <div className="menu-container" >
          {!menuOpen ? (
            <>
              <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideCD={hideCD} />
            </>
          ) : (
            <>
              <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} showCD={showCD}  />
            </>
          )}
        </div>
      </div>
    </>
  );
}
