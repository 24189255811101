import React, { lazy } from 'react'
import LoadImage from '../../Assets/svg/loading.webp';
import { Image } from 'react-bootstrap';
import './Loader.style.css'

export default function Loader() {
  return (
    <div className="loader">
   <Image src="https://res.cloudinary.com/dtju0fkhl/image/upload/v1666877584/loader_animation_logo_silicon_uflwfy.gif" fluid/>
     </div>
  )
}
