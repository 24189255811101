/**
 * @author
 * @version 1.0
 */

import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, lazy, Suspense } from "react";
import { HiringForm } from "./Pages/HiringForm";
//Custom components
import Home from "./Pages/Home";

import "./App.scss";
import Loader from "./Components/Loader/Loader";

const About = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const Blog = lazy(() => import("./Pages/Blog"));
const Portfolio = lazy(() => import("./Pages/Portfolio"));
const Careers = lazy(() => import("./Pages/Careers"));
const Service = lazy(() => import("./Pages/Services"));
const WebDesignService = lazy(() =>
  import("./Pages/Services/WebDesignDevelopment")
);
const MobileDesignService = lazy(() =>
  import("./Pages/Services/MobileDesignDevelopment")
);
const UiUxDesingService = lazy(() =>
  import("./Pages/Services/UIDesingDevelopment")
);
const BlockChainService = lazy(() =>
  import("./Pages/Services/BlockChainDevelopment")
);
const AiMiService = lazy(() => import("./Pages/Services/MlDesignDevelopment"));
const EmbeddedService = lazy(() =>
  import("./Pages/Services/EmbeddedDevelopment")
);
const StaffAugmentation = lazy(() => import("./Pages/StaffAugmentation"));
const BlogDetail = lazy(() => import("./Pages/BlogDetail"));


// const HiringForm = lazy(() => import("./Pages/HiringForm"));
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <>
    {/* <HiringForm/> */}
      <Wrapper />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home />
            // </Suspense>
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            <Suspense fallback={<Loader />}>
              <About />
            </Suspense>
          }
        />
        <Route
          exact
          path="/contact-us"
          element={
            <Suspense fallback={<Loader />}>
              <Contact />
            </Suspense>
          }
        />

        
        <Route
          exact
          path="/our-portfolio"
          element={
            <Suspense fallback={<Loader />}>
              <Portfolio />
            </Suspense>
          }
        />
        <Route
          exact
          path="/career"
          element={
            <Suspense fallback={<Loader />}>
              <Careers />
            </Suspense>
          }
        />
        <Route
          exact
          path="/career/:id"
          element={
            <Suspense fallback={<Loader />}>
              <Careers />
            </Suspense>
          }
        />
        <Route
          exact
          path="/our-services"
          element={
            <Suspense fallback={<Loader />}>
              <Service />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/web-design-service"
          element={
            <Suspense fallback={<Loader />}>
              <WebDesignService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/staff-augmentation"
          element={
            <Suspense fallback={<Loader />}>
              <StaffAugmentation />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/mobile-design-service"
          element={
            <Suspense fallback={<Loader />}>
              <MobileDesignService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/ui-ux-design-service"
          element={
            <Suspense fallback={<Loader />}>
              <UiUxDesingService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/block-chain-service"
          element={
            <Suspense fallback={<Loader />}>
              <BlockChainService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/ai-mi-service"
          element={
            <Suspense fallback={<Loader />}>
              <AiMiService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/service/embedded-service"
          element={
            <Suspense fallback={<Loader />}>
              <EmbeddedService />
            </Suspense>
          }
        />
        <Route
          exact
          path="/blogdetail/:id"
          element={
            <Suspense fallback={<Loader />}>
              <BlogDetail />
            </Suspense>
          }
        />
        {/* <Route
          exact
          path="/blog/:id"
          element={
            <Suspense fallback={<Loader />}>
              <Blog />
            </Suspense>
          }
        /> */}
          <Route
          exact
          path="/blog"
          element={
            <Suspense fallback={<Loader />}>
              <Blog />
            </Suspense>
          }
        />
        <Route
          exact
          path="/blog/:type/:name"
          element={
            <Suspense fallback={<Loader />}>
              <Blog />
            </Suspense>
          }
        />
        {/* <Route
          exact
          path="/blogs/:name"
          element={
            <Suspense fallback={<Loader />}>
              <ShowFilterBlogs />
            </Suspense>
          }
        />
         <Route
          exact
          path="/blogs"
          element={
            <Suspense fallback={<Loader />}>
              <ShowFilterBlogs />
            </Suspense>
          }
        /> */}
        <Route
          exact
          path="/hiringform"
          element={
            <Suspense fallback={<Loader />}>
              <HiringForm />
            </Suspense>
          }
        />
         <Route
          exact
          path="/hiringform/:id"
          element={
            <Suspense fallback={<Loader />}>
              <HiringForm />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default App;
