import React, { useState } from "react";
import "./styles.scss";
export default function EngagementModelCard({
  primaryText,
  secondaryText,
  primaryImage,
  secondaryImage,
  onMouseEnter,
  onMouseLeave,
  initial,
  setInitial
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
   
      setIsHovered(false);
    
  };

  const backgroundColor = initial || isHovered ? "white" : "";
  const textColor = initial || isHovered ? "#1480F3" : "white";
  const image = initial || isHovered ? secondaryImage : primaryImage;
  return (
    <div className="model-container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div
        className="model-card"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ backgroundColor: backgroundColor }}
      >
        <img src={image} alt="" className="model-image" />
      </div>
      <div style={{marginTop:"20px"}}>
        <div className="model-text" style={{ color: textColor }}>
          {primaryText}
        </div>
        <div className="model-text" style={{ color: textColor }}>
          {secondaryText}
        </div>
      </div>
    </div>
  );
}
