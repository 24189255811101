/**
 * @author Nadir H.
 * @version 1.0
 */

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import "./styles.scss";
export default function PageLinks({ path, current }) {
 

  
function handleClick(event) {
  
}

  return (
    <div role="presentation" onClick={handleClick}>
      
      <Breadcrumbs aria-label="breadcrumb">
        {path?.map((link, index) => {
          return (
            <Link
              underline="hover"
              href={link.url}
              className="parent-link"
              
            >
              {link.label}
            </Link>
          );
        })}

        <Typography className="current-link">{current}</Typography>
      </Breadcrumbs>
    </div>
  );
}
