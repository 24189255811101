import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CurvedArrow from "./CurvedArrow";
import DirectionArrow from "./DirectionArrow";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function ModelDetails({ details, bg }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box className="model-details-container">
        
        <h1 className="model-detail-heading">{details?.heading}</h1>
        <Grid container p={4}>
          <Grid item sx={12} sm={2} md={0.6} lg={0.6} xl={0.6}></Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2}>
            <StepDescription
              step={details?.process[0]}
              icon={details?.icons[0]}
            />
          </Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2}>
            <DirectionArrow direction="right" />
          </Grid>

          <Grid item sx={12} sm={2} md={2} lg={2} xl={2}>
            <StepDescription
              step={details?.process[1]}
              icon={details?.icons[1]}
            />
          </Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2}>
            <DirectionArrow direction="right" />
          </Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2}>
            <StepDescription
              step={details?.process[2]}
              icon={details?.icons[2]}
            />
          </Grid>

          <Grid item sx={12} sm={2} md={1} lg={1} xl={1}>
            <CurvedArrow />
          </Grid>

          <Grid item sx={12} sm={2} md={0.6} lg={0.6} xl={0.6}></Grid>

          <Grid item sx={12} sm={2} md={2} lg={2} xl={2} mt={-3}>
            <StepDescription
              step={details?.process[3]}
              icon={details?.icons[3]}
            />
          </Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2} mt={-3}>
            <DirectionArrow direction="left" />
          </Grid>

          <Grid item sx={12} sm={2} md={2} lg={2} xl={2} mt={-3}>
            <StepDescription
              step={details?.process[4]}
              icon={details?.icons[4]}
            />
          </Grid>
          <Grid item sx={12} sm={2} md={2} lg={2} xl={2} mt={-3}>
            <DirectionArrow direction="left" />
          </Grid>

          <Grid item sx={12} sm={2} md={2} lg={2} xl={2} mt={-3}>
            <StepDescription
              step={details?.process[5]}
              icon={details?.icons[5]}
            />
          </Grid>
        </Grid>

      </Box>
    </div>
  );
}

const StepDescription = ({ step, icon }) => {
  return (
    <>
     

      <Typography className="model-detail-step" mt={1}>
        {step}
      </Typography>
    </>
  );
};
